import React from 'react'
import LinkMaintainEmbedded from "./LinkMaintainEmbedded";

const Navigation = ({addDataTestAttr}) => {
  return (
    <div className="navigation">
      <LinkMaintainEmbedded className="button primary_button" dataTest={addDataTestAttr ? 'app' : ''} to='/'>Get the App</LinkMaintainEmbedded>
      <LinkMaintainEmbedded className="button secondary_button" dataTest={addDataTestAttr ? 'recommendations' : ''} to='/recommended/'>Camping Gear Recommendations</LinkMaintainEmbedded>
      <LinkMaintainEmbedded className="button secondary_button" dataTest={addDataTestAttr ? 'articles' : ''} to='/articles/'>Camping</LinkMaintainEmbedded>
    </div>
  )
}

export default Navigation