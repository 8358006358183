import React from 'react'
import { Link } from 'gatsby';

const LinkMaintainEmbedded  = ({children, to, embedded, webViewVersion, className, style, dataTest}) => {
  const maintainParams = (to) => {
    const urlHasParams = typeof window !== 'undefined' && window.location.search !== '';
    const toHasParams = to.indexOf('?') !== -1;
    const toHasHash = to.indexOf('#') !== -1;
    let toMinusHash, hashString;
    if (toHasHash) {
      // assumes hash is at the end
      hashString = to.substring(to.indexOf('#'));
      toMinusHash = to.substring(0, to.indexOf('#'));
    }
    if (urlHasParams && !toHasParams) {
      if (toHasHash) {
        to = toMinusHash + window.location.search + hashString;
      } else {
        to = to + window.location.search;
      }
    }
    if (embedded) {
      if (webViewVersion) {
        to = '/embedded/' + webViewVersion + to;
      } else {
        to = '/embedded/' + to;
      }
    }
    return to;
  }
  return (
    <Link to={maintainParams(to)} className={className} style={style} data-test={dataTest}>{children}</Link>
  )
}

export default LinkMaintainEmbedded;