// import PropTypes from 'prop-types'
import React from 'react'
// import {graphql} from "gatsby";
// import Img from "gatsby-image"
import logoImg from "../images/logo-dome-blue.png";
import Navigation from "./navigation"
// import { Link } from 'gatsby'

const Header = ({ data }) => {

  return (
    <div>
      <div className="app_title_wrapper">
        <div className="app_logo">
          <img src={logoImg} alt="Camping Checklist" title="Camping Checklist" />
        </div>
        <div className="app_title">
          Camping Checklist
        </div>
      </div>
      <div className="app_subtitle">
        Get organized for your next camping trip
      </div>
      <Navigation addDataTestAttr={true}/>
      <hr />
    </div>
  )
}

export default Header
