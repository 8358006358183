/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import '../styles/layout.css'
import '../styles/site.css'
import Navigation from "./navigation";
import LinkMaintainEmbedded from "./LinkMaintainEmbedded";
// import { isEmbedded } from "../utilities";

const Layout = ({ children, embedded }) => {

  return (
    <div className={!embedded ? "site not_embedded" : "site embedded"}>
      <div className="site_header_wrapper">
        {!embedded ? <Header /> : null}
      </div>
      <div className="site_inner_wrapper">
        <main>{children}</main>
        {
          !embedded
          ? <footer>
              <hr />
              <Navigation />
              © {new Date().getFullYear()}, <a href="https://www.stellarjaysoftware.com">StellarJay Software</a> | <LinkMaintainEmbedded to='/privacy-policy'>Privacy Policy</LinkMaintainEmbedded> | <LinkMaintainEmbedded to='/terms-conditions'>Terms and Conditions</LinkMaintainEmbedded> | <LinkMaintainEmbedded to='/support'>Support</LinkMaintainEmbedded>
            </footer>
          : null
        }
      </div>
      {!embedded
        ? <div className="photo_credit">
            Photo by <a href="https://unsplash.com/@riccardoch?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                      target="_blank" rel="noopener noreferrer">Riccardo Chiarini</a> on <a
            href="https://unsplash.com/collections/9418653/outdoors?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            target="_blank" rel="noopener noreferrer">Unsplash</a>
          </div>
        : null}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
